.intro {
  &-slider {
    height: 100%;
  }

  &-head {
    padding: 20px 0;
  }
}

.slider-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slider-container > div {
  flex: 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container > div:first-child {
  flex: 0 1;
}
