$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.5);

.titleContainer,
.contentContainer {
  padding: 0 var(--slmy-container-padding);

  hr {
    background-color: $borderColor;
    margin: 24px 0;
  }
}

.titleContainer {
  margin: 0 0 var(--slmy-container-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 28px;
    line-height: 40px;
    color: $colorWhite;
  }

  .favoriteIcon {
    flex-shrink: 0;
    font-size: 26px;
  }
}

.carouselSlide {
  > img {
    width: 100%;
    height: var(--slmy-video-thumbnail-height);
    object-fit: cover;
    object-position: center center;
  }
}

.video {
  width: 100%;
  height: var(--slmy-video-thumbnail-height);
  object-fit: cover;
}

.contentContainer {
  .description {
    color: $colorWhite;
    font-size: 16px;
    line-height: 28px;
  }

  .link {
    text-decoration: none;
  }

  .nextExerciseContainer {
    padding: 32px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .label {
      margin-right: 20px;
      color: $colorWhite;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
    }
    .arrow {
      width: 7px;
      height: 12px;
      flex-shrink: 0;
    }
  }
}
