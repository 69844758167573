.bg {
  height: 100%;
  background-image: url('https://bielo.eu/wp-content/uploads/2021/05/home_dulce.jpg');
  background-size: cover;
  background-position-x: 470px;
  background-position-y: -80px;
}

.spacer {
  height: 250px;
  width: 100%;
}

.body {
  position: absolute;
  width: 100%;
  bottom: 15px;
  padding: 30px;
  height: auto;
  z-index: 900;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.94);
}

.text {
  font-size: 15px;
  color: var(--ion-color-medium);
}

.item {
  margin: 0 0 0 -20px;
}

.item-input .item-native {
  padding-left: 0px !important;
}

.btn {
  width: 100%;
  margin-top: 30px;
}

.termsWrapper {
  margin-top: 20px;
  font-size: 14px;
}

.termsSubWrapper {
  margin-top: 20px;
  font-size: 12px;
}

.icon {
  margin-right: 10px;
}

// success page

.successPage {
  width: 100%;
  text-align: center;
}

.successIcon {
  font-size: 120px;
  color: var(--ion-color-primary);
}

.heading {
  font-size: 40px;
  font-family: 'PlayfairDisplay';
}

.subheading {
  font-size: 25px;
  font-family: 'PlayfairDisplay-Italic';
}
