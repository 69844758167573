.checkoutWraper {
  padding: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIconWrapper {
  display: flex;
  color: grey;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100%;
  font-size: 60px;
  margin-top: 100px;
}

.loadingIcon {
  animation: spin 2s linear infinite;
}

.submitButton {
  margin-top: 20px;
  width: 100%;
}
