.bg {
  height: 100%;
  background-size: cover;
  background-position-x: 470px;
  background-position-y: -80px;
}

.spacer {
  height: 250px;
  width: 100%;
}

.body {
  position: absolute;
  width: 100%;
  bottom: 15px;
  padding: 30px;
  height: auto;
  z-index: 900;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.94);
}

.text {
  font-size: 15px;
  color: var(--ion-color-medium);
}

.item {
  margin: 0 0 0 -20px;
}

.item-input .item-native {
  padding-left: 0px !important;
}

.btn {
  width: 100%;
  margin-top: 30px;
}

.termsWrapper {
  margin-top: 20px;
  font-size: 14px;
}

.icon {
  font-size: 30px;
}
