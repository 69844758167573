.carousel-slide {
  position: relative;

  > .gradient {
    position: absolute;
    left: 0;
    right: 0;

    &--top {
      height: 60px;
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &--bottom {
      color: var(--ion-color-light);
      height: 100px;
      bottom: 0;
      background: linear-gradient(
        0,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  > img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: center center;
  }

  &-description {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
  }
}
