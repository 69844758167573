.bg {
  background-color: #029e8f;
  background-image: linear-gradient(180deg, #02b1a0 0%, #01998a 100%);
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logoWrapper {
  padding: 10px;
  background-color: #ffffff2b;
  border-radius: 20px;
}

.heading {
  font-family: 'PlayfairDisplay';
  font-size: 36px;
  text-align: center;
}

.subheading {
  font-family: 'PlayfairDisplay-Italic';
  font-size: 15px;
  text-align: center;
}

.inputArea {
  width: 100%;
  margin-top: 5px;
}

.label {
  color: red;
}

.btnArea {
  margin-top: 15px;
  padding: 0 15px;
  width: 100%;
}

.btn {
  width: 100%;

  &Register {
    margin-top: 10px;
  }
}

.btnText {
  margin-left: 10px;
  color: #f0f4f4;
}

.linkWrapper {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.passwordForgetLink {
  color: #ffffffcc;
  font-size: 13px;
}

.loadingBar {
  position: absolute;
  top: 1px;
  background-color: white;
}
