/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function contrast($color) {
  @if (red($color) + 0.299 + green($color) + 0.587 + blue($color) * 0.114) > 186 {
    @return #000000;
  } @else {
    @return #ffffff;
  }
}

$theme-colors: (
  'primary': #01998a,
  'secondary': #01b7a5,
  'tertiary': #2da1ae,
  'success': #81c784,
  'warning': #ffb74d,
  'danger': #e57373,
  'dark': #92949c,
  'medium': #92949c,
  'light': #f0f4f4,
  'dark-turquoise': #38a4a0,
  'orange': #bf6529,
  'green': #73a33d,
  'turquoise': #52b2bd,
  'purple': #604e8b,
  'berry': #995c8a,
  'salmon': #e75e5e,
  'ocher': #d4910a,
  'pink': #ae3858,
  'dark-grey': rgb(40, 40, 40),
);

:root {
  // SmlY vars
  --slmy-padding: 15px;
  --padding-start: 0px;
  --slmy-video-thumbnail-height: 232px;
  --slmy-container-padding: 40px;

  @each $name, $value in $theme-colors {
    --ion-color-#{$name}: #{$value};
    --ion-color-#{$name}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
    --ion-color-#{$name}-shade: #{shade($value, 12%)};
    --ion-color-#{$name}-tint: #{tint($value, 10%)};
    --ion-color-#{$name}-contrast: #{contrast($value)};
    --ion-color-#{$name}-contrast-rgb: #{red(contrast($value))}, #{green(contrast($value))}, #{blue(contrast($value))};
  }
}

@each $name, $value in $theme-colors {
  .ion-color-#{$name} {
    --ion-color-base: var(--ion-color-#{$name}) !important;
    --ion-color-base-rgb: var(--ion-color-#{$name}-rgb) !important;
    --ion-color-contrast: var(--ion-color-#{$name}-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$name}-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-#{$name}-shade) !important;
    --ion-color-tint: var(--ion-color-#{$name}-tint) !important;
  }
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../fonts/PlayfairDisplay-Medium.ttf');
}

@font-face {
  font-family: 'PlayfairDisplay-Italic';
  src: url('../fonts/PlayfairDisplay-MediumItalic.ttf');
}

/*@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   

  body {
    --ion-color-primary: #01998a;
    --ion-color-primary-rgb: 1, 153, 138;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #018779;
    --ion-color-primary-tint: #1aa396;

    --ion-color-secondary: #01b7a5;
    --ion-color-secondary-rgb: 1, 183, 165;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #01a191;
    --ion-color-secondary-tint: #1abeae;

    --ion-color-tertiary: #2da1ae;
    --ion-color-tertiary-rgb: 45, 161, 174;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #288e99;
    --ion-color-tertiary-tint: #42aab6;

    --ion-color-success: #81c784;
    --ion-color-success-rgb: 129, 199, 132;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #72af74;
    --ion-color-success-tint: #8ecd90;

    --ion-color-warning: #ffb74d;
    --ion-color-warning-rgb: 255, 183, 77;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0a144;
    --ion-color-warning-tint: #ffbe5f;

    --ion-color-danger: #e57373;
    --ion-color-danger-rgb: 229, 115, 115;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #ca6565;
    --ion-color-danger-tint: #e88181;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f0f4f4;
    --ion-color-light-rgb: 240, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d3d7d7;
    --ion-color-light-tint: #f2f5f5;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
*/
