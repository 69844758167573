$containerPaddingHome: 110px;
$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.4);

.container {
  background: url('./assets/woman-half.svg') left 50px no-repeat, url('./assets/man-half.svg') right 50px no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $containerPaddingHome;

  .title {
    color: $colorWhite;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .bodyPartItem {
    padding: 38px 0;
    border-bottom: 1px solid $borderColor;
    width: 100%;
    color: $colorWhite;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    word-break: break-word;
    hyphens: auto;
    text-decoration: none;
    text-align: center;

    &:first-child {
      padding-top: 48px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
