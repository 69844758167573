.successWrapper {
  background: rgb(197, 205, 194);
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  background-color: rgba(255, 255, 255, 0.701);
  color: #353535;
  border-radius: 10px;
  margin: 20px;
  display: flex;
  height: auto;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

.successIcon {
  font-size: 15rem;
  color: var(--ion-color-primary);
}

.description {
  text-align: center;
}
