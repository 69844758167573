$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.4);

.headerContainer {
  margin: 20px 0 var(--slmy-container-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container, .headerContainer {
  padding: 0 var(--slmy-container-padding);


  .title {
    font-size: 28px;
    line-height: 40px;
    color: $colorWhite;
  }

  .exerciseListContainer {
    display: flex;
    flex-direction: column;
  }
}

.favoriteIcon {
  flex-shrink: 0;
  font-size: 26px;
}

.video {
  width: 100%;
  height: var(--slmy-video-thumbnail-height);
  object-fit: cover;
}

.description {
  margin: 24px 0;
  font-size: 16px;
  line-height: 28px;
  color: $colorWhite;
  position: relative;

  .descriptionIcon {
    display: none;
  }
}

.descriptionClosed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  .descriptionIconClosed {
    display: block;
    position: absolute;
    right: 2px;
    top: 62px;
    .ionicon {
      stroke: white !important;
    }
  }
}
