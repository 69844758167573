.intro {
  &-slide {
    padding: 16px 0 48px;
  }

  &-card {
    overflow: visible;
  }

  &-img {
    transform: scale(1.2);
    margin-top: -10px;
    margin-bottom: 0px;

    @media (min-height: 737px) {
      margin-bottom: 60px;
    }
  }

  &-card-title {
    margin-bottom: 20px;
    background-color: #ffffff97;
  }

  &-footer-bar {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;

    display: flex;
    justify-content: space-between;
    padding: 20px;

    > div {
      border-top: 1px solid var(--ion-color-light-shade);
      width: 100%;
      text-align: left;
      padding-top: 30px;
      padding-bottom: 10px;
    }

    > div:last-child {
      text-align: right;
    }
  }
}
