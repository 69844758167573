$borderColor: rgba(#fff, 0.5);

.link {
  text-decoration: none;
}

.contentContainer {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exerciseTitle {
  padding-right: 12px;
  white-space: normal;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.issueTitle {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 17px;
  color: rgb(226, 154, 173);
}

.iconArrow {
  width: 12px;
  height: 22px;
  flex-shrink: 0;
}

.item {
  --background: var(--ion-color-pink);
  --padding-start: 0;
  --color: white;
  border-top: 1px solid $borderColor;
}

.itemOptions {
  border-bottom: none;
}

.itemOption {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-light);
  border-top: 1px solid $borderColor;
}
