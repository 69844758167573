.height-100 {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  text-align: center;
}

.fullwidth {
  width: 100%;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px;
}

.exercise-carousel-wrapper {
  .control-dots {
    margin: 16px 0;

    .dot {
      width: 6px;
      height: 6px;
    }
  }
}

.header-md::after {
  background-image: none;
}

.item-interactive-disabled:not(.item-multiple-inputs) {
  opacity: 1;
}

.Premium_wrapper__1PhIM {
  .carousel .control-dots {
    top: 88%;
  }
}
