.toolbar {
  padding: 0 25px 0 30px;
}

.ionContent {
  margin-top: 30px;
}

.button {
  margin: 48px 0 16px;
}
