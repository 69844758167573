.container {
  padding: 0 var(--slmy-container-padding);
}

.inputItem {
  --padding-start: 0;
}

.emailButton,
.saveEmailButton,
.passwordButton,
.savePasswordButton,
.cancelButton,
.deleteAccountButton {
  --ion-color-turquoise-contrast: white;
}

.emailInputContainer,
.passwordInputContainer {
  margin-bottom: 16px;
}
.emailContainer,
.passwordContainer {
  margin-bottom: 32px;
}

.heading {
  color: var(--ion-color-dark-grey);
}

.cancelButton {
  margin-top: 20px;
}
