$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.4);

.tabContainer {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* Firefox */

  .tab {
    height: 70px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $colorWhite;
    font-size: 14px;
    line-height: 18px;
    margin-right: 20px;
    outline: none;

    &:first-child {
      margin-left: var(--slmy-container-padding);
    }
    &:last-child {
      margin-right: var(--slmy-container-padding);
    }
  }

  .activeTab {
    border-top: 4px solid $colorWhite;
    font-weight: bold;
  }
}

.issueListContainer {
  margin: 0 var(--slmy-container-padding);
}
