$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.4);

.ionList {
  background: none;
}

.title {
  margin: 0;
  padding: 48px 0 24px;
  color: white;
}

.listContainer {
  padding: 0 var(--slmy-container-padding);
}

.subtitle {
  color: white;
}