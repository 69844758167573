$borderColor: rgba(110, 148, 153, 0.3);

.container {
  padding: 0 var(--slmy-container-padding);

  hr {
    background-color: $borderColor;
    margin: 24px 0;
  }

  ul {
    list-style-type: square;
  }
}
