$issuesPadding: 40px;
$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.5);

.link {
  text-decoration: none;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $borderColor;
    padding: 24px 0;
    color: $colorWhite;

    .itemTitle {
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      padding-right: 12px;
    }
    .iconArrow {
      width: 12px;
      height: 22px;
      flex-shrink: 0;
    }
  }
}
