.wrapper {
  background-color: rgba(110, 148, 153, 0.1);
}

.iconClose {
  position: absolute;
  top: calc(var(--ion-safe-area-top) + 15px);
  right: 20px;
  z-index: 1000;
  width: 32px;
  height: 32px;
}

.card {
  position: relative;
  z-index: 100;
  margin: -30px 20px 24px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.text {
  margin: 0 20px;
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 18px;
}

.controlDots {
  margin: 50px 0;
}

.dot {
  width: 6px;
  height: 6px;
}

.ctaButton {
  color: white !important;
}

.prices {
  display: flex;
  flex-grow: 1;
  flex-basis: 1;
  max-width: 100%;
  flex-direction: row;
}

.priceButton {
  font-size: 16px;
  width: 50%;
  height: 100px;
  display: flex;
}
