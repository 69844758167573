$colorWhite: #fff;
$borderColor: rgba($colorWhite, 0.4);

.link {
  text-decoration: none;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  padding: 22px 0 24px;
  color: $colorWhite;

  .textContainer {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 10px;
    }
    .count {
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
    }
  }

  .issueLocked {
    color: $borderColor;
  }

  .iconPremium {
    width: 16px;
    height: 20px;
    flex-shrink: 0;
  }
}
